<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->

      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
    </v-skeleton-loader>
    <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />

    <v-card elevation="0">
      <v-card-title>
        Quarterly Plans
        <router-link
          class="ml-4"
          v-if="
            permissions.ninetyDayPlan && permissions.ninetyDayPlan.canCreate
          "
          :to="`/customers/${customerId}/plans/new`"
        >
          <v-btn class="orange-bg white--text" small>New</v-btn>
        </router-link>
        <router-link
          class="ml-4"
          v-if="
            permissions.ninetyDayPlan && permissions.ninetyDayPlan.canCreate
          "
          :to="`/customers/${customerId}/planTemplates`"
        >
          <v-btn class="orange-bg white--text" small>New From Template</v-btn>
        </router-link>
      </v-card-title>
      <v-card-text ref="actionSection">
        <!-- Start Quarterly Plans section -->
        <v-progress-linear
          :active="plansLoading"
          :indeterminate="plansLoading"
          absolute
          bottom
        ></v-progress-linear>
        <div v-if="!plansLoading">
          <v-row v-if="sortedNinetyDayPlans.length > 0">
            <div
              class="mx-4"
              v-for="(item, i) in sortedNinetyDayPlans"
              :key="'o' + i"
            >
              <div>
                <div class="d-flex justify-space-between">
                  <div>
                    <router-link
                      class="orange--text"
                      :to="`/customers/${customerId}/plans/${item.id}/view`"
                    >
                      {{ item.title }}
                    </router-link>
                  </div>
                  <div v-if="item.isActive">
                    <router-link
                      class="blue--text"
                      :to="`/customers/${customerId}/plans/${item.id}/edit`"
                    >
                      Update
                    </router-link>
                  </div>
                  <div v-if="!item.isActive">
                    <router-link
                      class="orange--text"
                      :to="`/customers/${customerId}/plans/${item.id}/view`"
                    >
                      Complete
                    </router-link>
                  </div>
                </div>
                <router-link
                  class="orange--text"
                  :to="
                    `/customers/${customerId}/plans/${item.id}/${
                      item.isActive ? 'edit' : 'view'
                    }`
                  "
                >
                  <v-img
                    height="200"
                    width="300"
                    :src="planPicture(item)"
                  ></v-img>
                </router-link>

                <p class="mt-2">
                  <router-link
                    class="blue--text"
                    :to="
                      `/customers/${customerId}/plans/new?sourcePlanId=${item.id}`
                    "
                  >
                    Duplicate plan (new)
                  </router-link>
                </p>
              </div>
            </div>
          </v-row>
          <v-row v-else>
            <v-col class="text-center">
              <div class="text-subtitle-1 text-center">
                No Quarterly Plan
              </div>
              <router-link
                v-if="
                  permissions.ninetyDayPlan &&
                    permissions.ninetyDayPlan.canCreate
                "
                :to="`/customers/${customerId}/plans/new`"
              >
                <v-btn class="orange-bg white--text" small
                  >Create Quarterly Plan</v-btn
                >
              </router-link>
            </v-col>
          </v-row>
        </div>

        <!-- End Quarterly Plans section -->
      </v-card-text>
    </v-card>

    <!-- </v-container> -->
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import { GET_QUERY, POST_DATA, API_CUSTOMERS } from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
// import { API_USERS } from "@/core/store/crud.module";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import { required } from "vuelidate/lib/validators";
// import moment from "moment";
import permissionsHelper from "@/core/helpers/permissionsHelper";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs
  },
  data: () => ({
    communityInfo: {},
    options: {
      page: 1,
      itemsPerPage: 5
    },
    ninetyDayPlans: [],
    sortedNinetyDayPlans: [],
    plansLoading: false,

    relatedCommunity: null,
    relatedCommunityItems: [],
    pageText: "",
    hasMore: false,
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: [],
    permissions: {},
    activePlanId: null
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return true;
    // return {
    //   billingInfo: {
    //     subscription: {
    //       nextBillingDates: {
    //         required,
    //         minDate: value =>
    //           dateFormatter.convertToMilliseconds(value) >
    //           dateFormatter.convertToMilliseconds(new Date())
    //       }
    //     }
    //   }
    // };
  },
  watch: {
    activePlanId() {
      this.activatePlan();
    }
  },
  async mounted() {
    this.getPlans();
  },
  async created() {
    await this.getComunityInfo();
    await permissionsHelper.getPermissions().then(this.getPermissions);
  },
  methods: {
    async activatePlan() {
      this.plansLoading = true;

      let plansUrl = `${API_CUSTOMERS}/${this.customerId}/plans/${this.activePlanId}/activate`;
      await this.$store
        .dispatch(POST_DATA, {
          listName: plansUrl
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            for (let i = 0; i < this.ninetyDayPlans.length; i++) {
              this.ninetyDayPlans[i].isActive =
                this.ninetyDayPlans[i].id == this.activePlanId;
            }
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.plansLoading = false;
        });
    },
    async getPlans() {
      this.plansLoading = true;

      let plansUrl = `${API_CUSTOMERS}/${this.customerId}/plans`;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: plansUrl
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.ninetyDayPlans = response.data.$values;

            // this.ninetyDayPlans.forEach(el => {
            //   if (el.isActive) this.activePlanId = el.id;
            // });
            this.sortedNinetyDayPlans = this.ninetyDayPlans
              .slice()
              .sort((a, b) => {
                return new Date(b.startDate) - new Date(a.startDate);
              });
            // if (communityInfo.ninetyDayPlans.length === 0) {
            //   this.$router.push({
            //     name: "NinetyDayPlanNew",
            //     params: { customerId: this.customerId }
            //   });
            // }
            // if (this.archivedPlans.length > 0)
            // this.plan = this.getActivePlan();
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.plansLoading = false;
        });
    },
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerDetails",
        params: { customerId: communityId }
      });
    },
    async getComunityInfo() {
      this.loading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.communityInfo = crud.getPopulatedComunityInfo(response.data);
            this.createBreadcrumb();
            this.setInternalBreadcrumbs();
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
        });
    },
    async setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        { title: "Quarterly Plans" }
      ];
    },
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Customers", route: "/customers" },
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        { title: "Quarterly Plans" }
      ]);
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },

    getPermissions(permissionsObj) {
      this.permissions = permissionsObj;
    },

    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      // return moment(date).format("MM/DD/YYYY");
      return dateFormatter.formatDateUs(date);
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    },
    planPicture(plan) {
      let defaultPicture =
        "https://cdn.vuetifyjs.com/images/parallax/material2.jpg";
      if (!plan) return defaultPicture;

      if (plan.photos.$values.length > 0) return plan.photos.$values[0].fileUrl;

      return defaultPicture;
    }
  },
  computed: {}
};
</script>
