var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-skeleton-loader',{staticClass:"mx-auto ml-2 mr-2",attrs:{"type":"card","loading":_vm.loading}},[_c('CommunityInfo',{attrs:{"communityInfo":_vm.communityInfo},on:{"onRelatedCommunityChanged":_vm.showRelatedCommunity}})],1),_c('InternalBreadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs}}),_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(" Quarterly Plans "),(
          _vm.permissions.ninetyDayPlan && _vm.permissions.ninetyDayPlan.canCreate
        )?_c('router-link',{staticClass:"ml-4",attrs:{"to":("/customers/" + _vm.customerId + "/plans/new")}},[_c('v-btn',{staticClass:"orange-bg white--text",attrs:{"small":""}},[_vm._v("New")])],1):_vm._e(),(
          _vm.permissions.ninetyDayPlan && _vm.permissions.ninetyDayPlan.canCreate
        )?_c('router-link',{staticClass:"ml-4",attrs:{"to":("/customers/" + _vm.customerId + "/planTemplates")}},[_c('v-btn',{staticClass:"orange-bg white--text",attrs:{"small":""}},[_vm._v("New From Template")])],1):_vm._e()],1),_c('v-card-text',{ref:"actionSection"},[_c('v-progress-linear',{attrs:{"active":_vm.plansLoading,"indeterminate":_vm.plansLoading,"absolute":"","bottom":""}}),(!_vm.plansLoading)?_c('div',[(_vm.sortedNinetyDayPlans.length > 0)?_c('v-row',_vm._l((_vm.sortedNinetyDayPlans),function(item,i){return _c('div',{key:'o' + i,staticClass:"mx-4"},[_c('div',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',[_c('router-link',{staticClass:"orange--text",attrs:{"to":("/customers/" + _vm.customerId + "/plans/" + (item.id) + "/view")}},[_vm._v(" "+_vm._s(item.title)+" ")])],1),(item.isActive)?_c('div',[_c('router-link',{staticClass:"blue--text",attrs:{"to":("/customers/" + _vm.customerId + "/plans/" + (item.id) + "/edit")}},[_vm._v(" Update ")])],1):_vm._e(),(!item.isActive)?_c('div',[_c('router-link',{staticClass:"orange--text",attrs:{"to":("/customers/" + _vm.customerId + "/plans/" + (item.id) + "/view")}},[_vm._v(" Complete ")])],1):_vm._e()]),_c('router-link',{staticClass:"orange--text",attrs:{"to":("/customers/" + _vm.customerId + "/plans/" + (item.id) + "/" + (item.isActive ? 'edit' : 'view'))}},[_c('v-img',{attrs:{"height":"200","width":"300","src":_vm.planPicture(item)}})],1),_c('p',{staticClass:"mt-2"},[_c('router-link',{staticClass:"blue--text",attrs:{"to":("/customers/" + _vm.customerId + "/plans/new?sourcePlanId=" + (item.id))}},[_vm._v(" Duplicate plan (new) ")])],1)],1)])}),0):_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('div',{staticClass:"text-subtitle-1 text-center"},[_vm._v(" No Quarterly Plan ")]),(
                _vm.permissions.ninetyDayPlan &&
                  _vm.permissions.ninetyDayPlan.canCreate
              )?_c('router-link',{attrs:{"to":("/customers/" + _vm.customerId + "/plans/new")}},[_c('v-btn',{staticClass:"orange-bg white--text",attrs:{"small":""}},[_vm._v("Create Quarterly Plan")])],1):_vm._e()],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }